/* eslint-disable no-useless-escape */
import React from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'
import { FaVolumeOff, FaVolumeUp } from 'react-icons/fa'

import TextButton from '../TextButton/Loadable'
import ModalOverlay from '../ModalOverlay/Loadable'
import TextInput from '../TextInput/Loadable'
import Dropdown from '../Dropdown/Loadable'
import Button from '../Button/Loadable'
import LabelInput from '../LabelInput/Loadable'
import ModalNotification from '../ModalNotification/Loadable'
import CheckboxComponent from '../CheckboxComponent/Loadable'

import './styled.scss'
import MoveIcon from '../../asset/images/camera-row-card/move-icon.svg'
import MESSAGE from './message'

const STATUS_ACTIVE = 'ACTIVE'
const STATUS_INACTIVE = 'INACTIVE'
const STATUS_OFFLINE = 'OFFLINE'

const DND_SITE_EDITOR_VALUE = 'DND_SITE_EDITOR_VALUE'

export class CameraRowCard extends React.PureComponent {
  state = {
    showDeleteCameraModal: false,
    showCameraDetailModal: false,
    showSpeakerDetailModal: false,
    showDeleteSpeakerDetailModal: false,
    cameraDetailData: {
      camera_id: this.props.id,
      camera_name: this.props.name,
      rtsp_address: '',
      latitude: '',
      longtitude: '',
      reading_fps: '',
      scale_factor: '',
      motion_threshold: '',
      number_grids_threshold: '',
      resolution_width: '',
      resolution_height: '',
      violation_count: '',
      violation_time: '',
      is_vehicle_detection: false
    },
    speakerDetailData: {
      id: undefined,
      name: '',
      method: '',
      url: '',
      payload: ''
    },
    listOptionMethod: [
      { text: 'PUT', value: 'PUT' },
      { text: 'POST', value: 'POST' },
      { text: 'PATCH', value: 'PATCH' },
      { text: 'GET', value: 'GET' },
      { text: 'DELETE', value: 'DELETE' }
    ],
    is_camera_name_valid: undefined,
    is_rtsp_address_valid: undefined,
    is_latitude_valid: undefined,
    is_longtitude_valid: undefined,
    is_reading_fps_valid: undefined,
    is_scale_factor_valid: undefined,
    is_motion_threshold_valid: undefined,
    is_number_grids_threshold_valid: undefined,
    is_violation_time_valid: undefined,
    is_violation_count_valid: undefined,
    is_resolution_width_valid: undefined,
    is_resolution_height_valid: undefined,
    is_speaker_name_valid: undefined,
    is_speaker_method_valid: undefined,
    is_speaker_url_valid: undefined
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  getDragPointSection() {
    const { connectDragSource } = this.props
    return connectDragSource(
      <div className="icon-container">
        <div className="icon">
          <img src={MoveIcon} alt="" />
        </div>
      </div>
    )
  }

  getCameraNameSection() {
    let cameraStatusText = ''
    switch (this.props.status.toUpperCase()) {
      case STATUS_ACTIVE:
        cameraStatusText = this.getMessage('online_status')
        break
      case STATUS_INACTIVE:
        cameraStatusText = this.getMessage('inactive_status')
        break
      case STATUS_OFFLINE:
        cameraStatusText = this.getMessage('offline_status')
        break
    }
    return (
      <div className="camera-name-container">
        <div className="camera-name prevent-text-overflow">{this.props.name}</div>
        <TextButton
          className="camera-name-status"
          label={cameraStatusText}
          primary={this.props.status === STATUS_ACTIVE}
          danger={this.props.status === STATUS_OFFLINE}
        />
      </div>
    )
  }

  handleCameraDetailClicked = () => {
    const latitude = this.props.cameraData.gps.split(',')[0]
    const longtitude = this.props.cameraData.gps.split(',')[1]
    this.setState({
      showCameraDetailModal: true,
      cameraDetailData: {
        camera_id: this.props.id,
        camera_name: this.props.name,
        rtsp_address: this.props.cameraData.rtsp_address + '',
        latitude: latitude + '',
        longtitude: longtitude + '',
        reading_fps: this.props.cameraData.reading_fps + '',
        scale_factor: this.props.cameraData.scale_factor + '',
        motion_threshold: this.props.cameraData.motion_threshold + '',
        number_grids_threshold: this.props.cameraData.num_grids_threshold + '',
        resolution_width: this.props.cameraData.resolution_width + '',
        resolution_height: this.props.cameraData.resolution_height + '',
        violation_time: this.props.cameraData.violation_time + '',
        violation_count: this.props.cameraData.violation_count + '',
        is_vehicle_detection: this.props.cameraData.is_vehicle_detection
      }
    })
  }

  setShowRemoveCameraModal = () => {
    this.setState({
      showDeleteCameraModal: true
    })
  }

  getCameraRemoveBtnSection() {
    return (
      <div className="camera-detail-remove-container">
        <TextButton className="camera-detail-btn" label={this.getMessage('remove_btn_txt')} onClick={this.setShowRemoveCameraModal} danger />
      </div>
    )
  }

  handleSpeakerBtnClicked = () => {
    this.setShowSpeakerDetailModal()
  }

  setShowSpeakerDetailModal = () => {
    this.setState({
      showSpeakerDetailModal: true,
      speakerDetailData: {
        id: this.props.speakerData?.id || undefined,
        name: this.props.speakerData?.name || '',
        method: this.props.speakerData?.method || '',
        url: this.props.speakerData?.url || '',
        payload: this.props.speakerData?.payload || ''
      }
    })
  }

  handleCloseSpeakerDetailModal = () => {
    this.setState({
      showSpeakerDetailModal: false,
      speakerDetailData: {
        id: undefined,
        name: '',
        method: '',
        url: '',
        payload: ''
      },
      is_speaker_name_valid: undefined,
      is_speaker_method_valid: undefined,
      is_speaker_url_valid: undefined
    })
  }

  getCameraOptionSection() {
    const speakerIcon = this.props.speakerData ? <FaVolumeUp /> : <FaVolumeOff />
    return (
      <div className="camera-detail-option-container">
        <TextButton className="camera-detail-btn" label={speakerIcon} onClick={this.handleSpeakerBtnClicked} primary />
        <TextButton className="camera-detail-btn" label={this.getMessage('detail_btn_txt')} onClick={this.handleCameraDetailClicked} />
      </div>
    )
  }

  handleCloseShowDeleteCameraModal = () => {
    this.setState({
      showDeleteCameraModal: false
    })
  }

  handleDeleteCameraBtnClicked = () => {
    this.handleCloseShowDeleteCameraModal()
    this.props.onDeleteCamera(this.props.id, this.props.areaID, this.props.siteID)
  }

  getDeleteCameraModal() {
    let output = null
    if (this.state.showDeleteCameraModal) {
      output = (
        <ModalNotification
          title={this.getMessage('delete_camera_dialog_title')}
          message={this.getMessage('delete_camera_dialog_message')}
          onSecondaryButtonClick={this.handleCloseShowDeleteCameraModal}
          onPrimaryButtonClick={this.handleDeleteCameraBtnClicked}
          onOverLayClose={this.handleCloseShowDeleteCameraModal}
          primaryButtonText={this.getMessage('delete_camera_dialog_delete_btn_txt')}
          secondaryButtonText={this.getMessage('delete_camera_dialog_cancel_btn_txt')}
        />
      )
    }
    return output
  }

  handleCloseCameraDetailModal = () => {
    this.setState({
      showCameraDetailModal: false,
      cameraDetailData: {
        camera_id: this.props.id,
        camera_name: this.props.name,
        rtsp_address: '',
        latitude: '',
        longtitude: '',
        reading_fps: '',
        scale_factor: '',
        motion_threshold: '',
        number_grids_threshold: '',
        resolution_width: '',
        resolution_height: '',
        violation_count: '',
        violation_time: '',
        is_vehicle_detection: false
      },
      is_camera_name_valid: undefined,
      is_rtsp_address_valid: undefined,
      is_latitude_valid: undefined,
      is_longtitude_valid: undefined,
      is_reading_fps_valid: undefined,
      is_scale_factor_valid: undefined,
      is_motion_threshold_valid: undefined,
      is_number_grids_threshold_valid: undefined,
      is_violation_time_valid: undefined,
      is_violation_count_valid: undefined,
      is_resolution_width_valid: undefined,
      is_resolution_height_valid: undefined
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.cameraData.resolution_width !== this.props.cameraData.resolution_width ||
      prevProps.cameraData.resolution_height !== this.props.cameraData.resolution_height ||
      prevProps.cameraData.prev_resolution_width !== this.props.cameraData.prev_resolution_width ||
      prevProps.cameraData.prev_resolution_height !== this.props.cameraData.prev_resolution_height
    ) {
      this._handleResolutionChange()
    }
  }

  _handleResolutionChange = () => {
    this.setState({
      cameraDetailData: Object.assign({}, this.state.cameraDetailData, {
        resolution_width: `${this.props.cameraData.resolution_width}`,
        resolution_height: `${this.props.cameraData.resolution_height}`,
        prev_resolution_width: `${this.props.cameraData.prev_resolution_width}`,
        prev_resolution_height: `${this.props.cameraData.prev_resolution_height}`
      })
    })
  }

  handleConnectCameraBtnClicked = () => {
    if (this._isCameraDataValid()) {
      const cameraDetailData = Object.assign({}, this.state.cameraDetailData, {
        prev_resolution_width: `${this.props.cameraData.resolution_width}`,
        prev_resolution_height: `${this.props.cameraData.resolution_height}`
      })
      this.props.onUpdateCameraData(cameraDetailData, this.props.areaID, this.props.siteID)
      this.handleCloseCameraDetailModal()
    }
  }

  handleCreateCameraInputChanged = (value, property) => {
    this.setState({
      cameraDetailData: Object.assign({}, this.state.cameraDetailData, {
        [property]: property === 'is_vehicle_detection' ? value.checked : value
      })
    })
  }

  isLocationFormatValid = (value) => {
    const locationRegex = /^-?\d*(\.\d+)?$/
    return locationRegex.test(value)
  }

  isNumberValid = (value) => {
    const resolutionRegex = /^\d{1,10}$/
    return resolutionRegex.test(value)
  }

  isViolationCountAndTimeValid = (value) => {
    const regexText = /^0*[1-9]\d*$/
    return regexText.test(value)
  }

  _isCameraDataValid = () => {
    const is_camera_name_valid = this.state.cameraDetailData.camera_name.trim().length > 0
    const is_rtsp_address_valid = this.state.cameraDetailData.rtsp_address.trim().length > 0
    const is_latitude_valid =
      this.state.cameraDetailData.latitude.trim().length > 0 && this.isLocationFormatValid(this.state.cameraDetailData.latitude.trim())
    const is_longtitude_valid =
      this.state.cameraDetailData.longtitude.trim().length > 0 && this.isLocationFormatValid(this.state.cameraDetailData.longtitude.trim())
    const is_reading_fps_valid = this.isNumberValid(this.state.cameraDetailData.reading_fps.trim())
    const is_scale_factor_valid = this.isNumberValid(this.state.cameraDetailData.scale_factor.trim())
    const is_motion_threshold_valid = this.isNumberValid(this.state.cameraDetailData.motion_threshold.trim())
    const is_number_grids_threshold_valid = this.isNumberValid(this.state.cameraDetailData.number_grids_threshold.trim())
    const is_violation_time_valid = this.props.currentUser.manager
      ? true
      : this.isViolationCountAndTimeValid(this.state.cameraDetailData.violation_time.trim())
    const is_violation_count_valid = this.props.currentUser.manager
      ? true
      : this.isViolationCountAndTimeValid(this.state.cameraDetailData.violation_count.trim())
    const is_resolution_width_valid = this.isNumberValid(this.state.cameraDetailData.resolution_width.trim())
    const is_resolution_height_valid = this.isNumberValid(this.state.cameraDetailData.resolution_height.trim())
    this.setState({
      is_camera_name_valid,
      is_rtsp_address_valid,
      is_latitude_valid,
      is_longtitude_valid,
      is_reading_fps_valid,
      is_scale_factor_valid,
      is_motion_threshold_valid,
      is_number_grids_threshold_valid,
      is_violation_count_valid,
      is_violation_time_valid,
      is_resolution_width_valid,
      is_resolution_height_valid
    })
    return (
      is_camera_name_valid &&
      is_rtsp_address_valid &&
      is_latitude_valid &&
      is_longtitude_valid &&
      is_reading_fps_valid &&
      is_scale_factor_valid &&
      is_motion_threshold_valid &&
      is_number_grids_threshold_valid &&
      is_violation_count_valid &&
      is_violation_time_valid &&
      is_resolution_width_valid &&
      is_resolution_height_valid
    )
  }

  handleSpeakerDetailInputChanged = (newValue, property) => {
    this.setState({
      speakerDetailData: Object.assign({}, this.state.speakerDetailData, {
        [property]: newValue
      })
    })
  }

  renderViolationCountAndTimeInput = () => {
    let output = null
    if (this.props.currentUser.admin) {
      output = (
        <div className="modal-content-row-container half">
          <TextInput
            mode="material-design"
            type="text"
            label={this.getMessage('camera_detail_violation_period_label_txt')}
            id="edit-violation-time-input"
            placeHolder={this.getMessage('camera_detail_violation_period_placeholder_txt')}
            value={this.state.cameraDetailData.violation_time}
            onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'violation_time')}
            errorText={this.state.is_violation_time_valid === false ? this.getMessage('camera_detail_violation_period_error_txt') : ''}
          />
          <TextInput
            mode="material-design"
            type="text"
            label={this.getMessage('camera_detail_min_violation_count_label_txt')}
            id="edit-vioolation-count-input"
            placeHolder={this.getMessage('camera_detail_min_violation_count_placeholder_txt')}
            value={this.state.cameraDetailData.violation_count}
            onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'violation_count')}
            errorText={this.state.is_violation_count_valid === false ? this.getMessage('camera_detail_min_violation_count_error_txt') : ''}
          />
        </div>
      )
    }
    return output
  }

  getCameraDetailModal() {
    let output = null
    if (this.state.showCameraDetailModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseCameraDetailModal}>
          <div className="modal-content-container camera-detail" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content-header-container">{this.getMessage('camera_detail_header_title_txt')}</div>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_rtsp_label_txt')}
                  id="edit-rtsp-address-input"
                  placeHolder={this.getMessage('camera_detail_rtsp_placeholder_txt')}
                  value={this.state.cameraDetailData.rtsp_address}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'rtsp_address')}
                  errorText={this.state.is_rtsp_address_valid === false ? this.getMessage('camera_detail_rtsp_error_txt') : ''}
                />
              </div>
              <div className="modal-content-row-container half">
                <div className="full-width">
                  <TextInput
                    mode="material-design"
                    type="text"
                    label={this.getMessage('camera_detail_title_label_txt')}
                    id="edit-camera-name-input"
                    placeHolder={this.getMessage('camera_detail_title_placeholder_txt')}
                    value={this.state.cameraDetailData.camera_name}
                    onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'camera_name')}
                    errorText={this.state.is_camera_name_valid === false ? this.getMessage('camera_detail_title_error_txt') : ''}
                  />
                </div>
                <div className="full-width">
                  <LabelInput className="label-material full-width" text={this.getMessage('camera_detail_resolution_label_txt')} />
                  <div className="flex">
                    <div className="full-width modal-input-container">
                      <input
                        type="text"
                        onChange={(e) => this.handleCreateCameraInputChanged(e.target.value, 'resolution_width')}
                        value={this.state.cameraDetailData.resolution_width}
                      />
                      <div className="validate-error-message">
                        {this.state.is_resolution_width_valid === false ? this.getMessage('camera_detail_resolution_error_txt') : ''}
                      </div>
                    </div>
                    <div className="flex justify-center align-items-center" style={{ width: 40 }}>
                      x
                    </div>
                    <div className="full-width modal-input-container">
                      <input
                        type="text"
                        onChange={(e) => this.handleCreateCameraInputChanged(e.target.value, 'resolution_height')}
                        value={this.state.cameraDetailData.resolution_height}
                      />
                      <div className="validate-error-message">
                        {this.state.is_resolution_height_valid === false ? this.getMessage('camera_detail_resolution_error_txt') : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-content-row-container half">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_latitude_label_txt')}
                  id="edit-camera-lat-input"
                  placeHolder={this.getMessage('camera_detail_latitude_placeholder_txt')}
                  value={this.state.cameraDetailData.latitude}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'latitude')}
                  errorText={this.state.is_latitude_valid === false ? this.getMessage('camera_detail_latitude_error_txt') : ''}
                />
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_longtitude_label_txt')}
                  id="edit-camera-long-input"
                  placeHolder={this.getMessage('camera_detail_longtitude_placeholder_txt')}
                  value={this.state.cameraDetailData.longtitude}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'longtitude')}
                  errorText={this.state.is_longtitude_valid === false ? this.getMessage('camera_detail_longtitude_error_txt') : ''}
                />
              </div>
              <div className="modal-content-row-container half">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_fps_label_txt')}
                  id="edit-reading-fps-input"
                  placeHolder={this.getMessage('camera_detail_fps_placeholder_txt')}
                  value={this.state.cameraDetailData.reading_fps}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'reading_fps')}
                  errorText={this.state.is_reading_fps_valid === false ? this.getMessage('camera_detail_fps_error_txt') : ''}
                />
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_scale_factor_label_txt')}
                  id="edit-scale-factor-input"
                  placeHolder={this.getMessage('camera_detail_scale_factor_placeholder_txt')}
                  value={this.state.cameraDetailData.scale_factor}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'scale_factor')}
                  errorText={this.state.is_scale_factor_valid === false ? this.getMessage('camera_detail_scale_factor_error_txt') : ''}
                />
              </div>
              <div className="modal-content-row-container half">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_motion_threshold_label_txt')}
                  id="edit-motion-threshold-input"
                  placeHolder={this.getMessage('camera_detail_motion_threshold_placeholder_txt')}
                  value={this.state.cameraDetailData.motion_threshold}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'motion_threshold')}
                  errorText={this.state.is_motion_threshold_valid === false ? this.getMessage('camera_detail_motion_threshold_error_txt') : ''}
                />
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('camera_detail_number_grid_threshold_label_txt')}
                  id="edit-num-grid-input"
                  placeHolder={this.getMessage('camera_detail_number_grid_threshold_placeholder_txt')}
                  value={this.state.cameraDetailData.number_grids_threshold}
                  onChange={(e, newValue) => this.handleCreateCameraInputChanged(newValue, 'number_grids_threshold')}
                  errorText={
                    this.state.is_number_grids_threshold_valid === false ? this.getMessage('camera_detail_number_grid_threshold_error_txt') : ''
                  }
                />
              </div>
              {this.renderViolationCountAndTimeInput()}
              <div className="modal-content-row-container">
                <CheckboxComponent
                  id="vehicle-detection-checkbox"
                  checked={this.state.cameraDetailData.is_vehicle_detection}
                  onChange={(newValue) => this.handleCreateCameraInputChanged(newValue, 'is_vehicle_detection')}
                  label={'Enable vehicle detection'}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="connect-camera-btn"
              onClick={this.handleConnectCameraBtnClicked}
              text={this.getMessage('camera_detail_connect_btn_txt')}
              disabled={this.props.isRecalculatingZone}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  isURLValid = (url) => {
    const urlRex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
    return urlRex.test(url)
  }

  _isSpeakerInputValid = () => {
    const is_speaker_name_valid = this.state.speakerDetailData.name.trim().length > 0
    const is_speaker_method_valid = this.state.speakerDetailData.method.trim().length > 0
    const is_speaker_url_valid = this.isURLValid(this.state.speakerDetailData.url.trim())
    this.setState({
      is_speaker_name_valid,
      is_speaker_method_valid,
      is_speaker_url_valid
    })
    return is_speaker_name_valid && is_speaker_method_valid && is_speaker_url_valid
  }

  handleConnectSpeakerBtnClicked = () => {
    if (this._isSpeakerInputValid()) {
      const { cameraData, areaID, siteID } = this.props
      this.state.speakerDetailData.id
        ? this.props.onUpdateSpeaker(this.state.speakerDetailData, cameraData, areaID, siteID)
        : this.props.onCreateSpeaker(this.state.speakerDetailData, cameraData, areaID, siteID)
      this.handleCloseSpeakerDetailModal()
    }
  }

  handleDeleteSpeakerBtnClicked = () => {
    const { cameraData, areaID, siteID } = this.props
    this.props.onDeleteSpeaker(this.state.speakerDetailData, cameraData, areaID, siteID)
    this.handleCloseDeleteSpeakerDetailModal()
    this.handleCloseSpeakerDetailModal()
  }

  showDeleteSpeakerModal = () => {
    this.setState({
      showDeleteSpeakerDetailModal: true
    })
  }
  handleCloseDeleteSpeakerDetailModal = () => {
    this.setState({
      showDeleteSpeakerDetailModal: false
    })
  }

  getSpeakerDetailModal() {
    let output = null
    if (this.state.showSpeakerDetailModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseSpeakerDetailModal}>
          <div className="modal-content-container speaker-modal-container" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content-header-container">Speaker</div>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('speaker_detail_name_txt')}
                  id="speaker-name-input"
                  placeHolder="Alert1"
                  value={this.state.speakerDetailData.name}
                  onChange={(e, newValue) => this.handleSpeakerDetailInputChanged(newValue, 'name')}
                  errorText={this.state.is_speaker_name_valid === false ? this.getMessage('camera_detail_rtsp_error_txt') : ''}
                />
              </div>
              <div className="modal-content-row-container">
                <Dropdown
                  id="speaker-method-input"
                  className="speaker-dropdown-container"
                  label={this.getMessage('speaker_detail_method_txt')}
                  labelPosition="top"
                  placeHolder="Alert1"
                  options={this.state.listOptionMethod}
                  value={this.state.speakerDetailData.method}
                  onChange={(data) => this.handleSpeakerDetailInputChanged(data.value, 'method')}
                  language={this.props.currentUser?.language}
                />
              </div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('speaker_detail_url_txt')}
                  id="speaker-url-input"
                  placeHolder="https://speaker.com"
                  value={this.state.speakerDetailData.url}
                  onChange={(e, newValue) => this.handleSpeakerDetailInputChanged(newValue, 'url')}
                  errorText={this.state.is_speaker_url_valid === false ? this.getMessage('camera_detail_rtsp_error_txt') : ''}
                />
              </div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('speaker_detail_payload_txt')}
                  id="speaker-payload-input"
                  placeHolder="AlertCamera1"
                  value={this.state.speakerDetailData.payload}
                  onChange={(e, newValue) => this.handleSpeakerDetailInputChanged(newValue, 'payload')}
                  errorText={this.state.is_speaker_payload_valid === false ? this.getMessage('camera_detail_rtsp_error_txt') : ''}
                />
              </div>
            </div>
            <div className="speaker-btn-container">
              <Button
                className="speaker-modal-btn"
                id="delete-speaker-btn"
                onClick={this.showDeleteSpeakerModal}
                text={this.getMessage('speaker_detail_disconnect_btn_txt')}
                invert
                disabled={!this.state.speakerDetailData.id}
              />
              <Button
                className="speaker-modal-btn"
                id="submit-speaker-btn"
                onClick={this.handleConnectSpeakerBtnClicked}
                text={this.getMessage('speaker_detail_connect_btn_txt')}
              />
            </div>
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  getDeleteSpeakerDetailModal = () => {
    let output = null
    if (this.state.showDeleteSpeakerDetailModal) {
      output = (
        <ModalNotification
          title={this.getMessage('delete_speaker_dialog_title')}
          message={this.getMessage('delete_speaker_dialog_message')}
          onSecondaryButtonClick={this.handleCloseDeleteSpeakerDetailModal}
          onPrimaryButtonClick={this.handleDeleteSpeakerBtnClicked}
          onOverLayClose={this.handleCloseDeleteSpeakerDetailModal}
          primaryButtonText={this.getMessage('delete_speaker_dialog_delete_btn_txt')}
          secondaryButtonText={this.getMessage('delete_speaker_dialog_cancel_btn_txt')}
        />
      )
    }
    return output
  }

  render() {
    const { connectDragPreview } = this.props
    return connectDragPreview(
      <div className="camera-detail-card-container">
        {this.getDragPointSection()}
        {this.getCameraNameSection()}
        {this.getCameraOptionSection()}
        {this.getCameraRemoveBtnSection()}
        {this.getSpeakerDetailModal()}
        {this.getDeleteSpeakerDetailModal()}
        {this.getDeleteCameraModal()}
        {this.getCameraDetailModal()}
      </div>
    )
  }
}

CameraRowCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  siteID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  areaID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  speakerData: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    method: PropTypes.string,
    url: PropTypes.string,
    payload: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
    firstname: PropTypes.string,
    language: PropTypes.string,
    lastname: PropTypes.string,
    manager: PropTypes.bool,
    token: PropTypes.string
  }),
  onAddCameraBetweenArea: PropTypes.func.isRequired,
  onDeleteCamera: PropTypes.func.isRequired,
  onUpdateCameraData: PropTypes.func.isRequired,
  onCreateSpeaker: PropTypes.func.isRequired,
  onUpdateSpeaker: PropTypes.func.isRequired,
  onDeleteSpeaker: PropTypes.func.isRequired,
  isRecalculatingZone: PropTypes.bool
}

const cameraRowCardDragSource = {
  isDragging(props, monitor) {
    return monitor.getItem().id === props.id
  },
  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    return props
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }
    const draggingData = monitor.getItem()
    const dropTargetResultData = monitor.getDropResult()
    const isSameSite = draggingData.siteID === dropTargetResultData.siteID
    const isAreaIDValid = draggingData.areaID && dropTargetResultData.areaID
    const isDropInAnotherArea = draggingData.areaID !== dropTargetResultData.areaID
    if (isSameSite && isAreaIDValid && isDropInAnotherArea && dropTargetResultData.listCamera.length < 5) {
      props.onAddCameraBetweenArea(draggingData, dropTargetResultData)
    }
  }
}

const dragCollect = (connect, monitor) => {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

export default DragSource(DND_SITE_EDITOR_VALUE, cameraRowCardDragSource, dragCollect)(CameraRowCard)
