const MESSAGE = {
  EN: {
    online_status: 'Online',
    inactive_status: 'Inactive',
    offline_status: 'Offline',
    detail_btn_txt: 'Detail',
    remove_btn_txt: 'Remove',
    delete_camera_dialog_title: 'Delete Camera',
    delete_camera_dialog_message: 'Are you sure to delete camera ?',
    delete_camera_dialog_delete_btn_txt: 'Delete',
    delete_camera_dialog_cancel_btn_txt: 'Cancel',
    camera_detail_header_title_txt: 'Camera Details',
    camera_detail_title_label_txt: 'CAMERA TITLE',
    camera_detail_title_placeholder_txt: 'Camera Title',
    camera_detail_title_error_txt: 'Invalid.',
    camera_detail_rtsp_label_txt: 'RTSP ADDRESS',
    camera_detail_rtsp_placeholder_txt: 'RTSP address',
    camera_detail_rtsp_error_txt: 'Invalid.',
    camera_detail_latitude_label_txt: 'LATITUDE',
    camera_detail_latitude_placeholder_txt: 'Latitude',
    camera_detail_latitude_error_txt: 'Invalid.',
    camera_detail_longtitude_label_txt: 'LONGTITUDE',
    camera_detail_longtitude_placeholder_txt: 'Longtitude',
    camera_detail_longtitude_error_txt: 'Invalid.',
    camera_detail_fps_label_txt: 'READING FPS',
    camera_detail_fps_placeholder_txt: 'Reading FPS',
    camera_detail_fps_error_txt: 'Invalid.',
    camera_detail_scale_factor_label_txt: 'SCALE FACTOR',
    camera_detail_scale_factor_placeholder_txt: 'Scale Factor',
    camera_detail_scale_factor_error_txt: 'Invalid.',
    camera_detail_motion_threshold_label_txt: 'MOTION THRESHOLD',
    camera_detail_motion_threshold_placeholder_txt: 'Motion Threshold',
    camera_detail_motion_threshold_error_txt: 'Invalid.',
    camera_detail_number_grid_threshold_label_txt: 'NUMBER GRIDS THRESHOLD',
    camera_detail_number_grid_threshold_placeholder_txt: 'Number Grids Threshold',
    camera_detail_number_grid_threshold_error_txt: 'Invalid.',
    camera_detail_violation_period_label_txt: 'CASE RESET PERIOD (SECOND)',
    camera_detail_violation_period_placeholder_txt: 'Case Reset Period',
    camera_detail_violation_period_error_txt: 'Invalid',
    camera_detail_min_violation_count_label_txt: 'MINIMUM CASE TO RESET PERIOD',
    camera_detail_min_violation_count_placeholder_txt: 'Minimum Case To Reset Period',
    camera_detail_min_violation_count_error_txt: 'Invalid',
    camera_detail_resolution_label_txt: 'RESOLUTION ( WIDTH x HEIGHT )',
    camera_detail_resolution_error_txt: 'Invalid.',
    camera_detail_connect_btn_txt: 'Connect',
    speaker_detail_name_txt: 'Name',
    speaker_detail_method_txt: 'Method',
    speaker_detail_url_txt: 'URL',
    speaker_detail_payload_txt: 'Payload',
    speaker_detail_connect_btn_txt: 'Connect',
    speaker_detail_disconnect_btn_txt: 'Delete',
    delete_speaker_dialog_title: 'Delete Speaker',
    delete_speaker_dialog_message: 'Are you sure to delete speaker ?',
    delete_speaker_dialog_delete_btn_txt: 'Delete',
    delete_speaker_dialog_cancel_btn_txt: 'Cancel'
  },
  TH: {
    online_status: 'ออนไลน์',
    inactive_status: 'Inactive',
    offline_status: 'ออฟไลน์',
    detail_btn_txt: 'ข้อมูล',
    remove_btn_txt: 'ลบ',
    delete_camera_dialog_title: 'ลบกล้อง',
    delete_camera_dialog_message: 'คุณต้องการที่จะลบกล้องใช่หรือไม่ ?',
    delete_camera_dialog_delete_btn_txt: 'ลบ',
    delete_camera_dialog_cancel_btn_txt: 'ยกเลิก',
    camera_detail_header_title_txt: 'ข้อมูลกล้อง',
    camera_detail_title_label_txt: 'ชื่อกล้อง',
    camera_detail_title_placeholder_txt: 'ชื่อกล้อง',
    camera_detail_title_error_txt: 'Invalid.',
    camera_detail_rtsp_label_txt: 'RTSP ADDRESS',
    camera_detail_rtsp_placeholder_txt: 'RTSP address',
    camera_detail_rtsp_error_txt: 'Invalid.',
    camera_detail_latitude_label_txt: 'LATITUDE',
    camera_detail_latitude_placeholder_txt: 'Latitude',
    camera_detail_latitude_error_txt: 'Invalid.',
    camera_detail_longtitude_label_txt: 'LONGTITUDE',
    camera_detail_longtitude_placeholder_txt: 'Longtitude',
    camera_detail_longtitude_error_txt: 'Invalid.',
    camera_detail_fps_label_txt: 'READING FPS',
    camera_detail_fps_placeholder_txt: 'Reading FPS',
    camera_detail_fps_error_txt: 'Invalid.',
    camera_detail_scale_factor_label_txt: 'SCALE FACTOR',
    camera_detail_scale_factor_placeholder_txt: 'Scale Factor',
    camera_detail_scale_factor_error_txt: 'Invalid.',
    camera_detail_motion_threshold_label_txt: 'MOTION THRESHOLD',
    camera_detail_motion_threshold_placeholder_txt: 'Motion Threshold',
    camera_detail_motion_threshold_error_txt: 'Invalid.',
    camera_detail_number_grid_threshold_label_txt: 'NUMBER GRIDS THRESHOLD',
    camera_detail_number_grid_threshold_placeholder_txt: 'Number Grids Threshold',
    camera_detail_number_grid_threshold_error_txt: 'Invalid.',
    camera_detail_violation_period_label_txt: 'CASE RESET PERIOD (SECOND)',
    camera_detail_violation_period_placeholder_txt: 'Case Reset Period',
    camera_detail_violation_period_error_txt: 'Invalid',
    camera_detail_min_violation_count_label_txt: 'MINIMUM CASE TO RESET PERIOD',
    camera_detail_min_violation_count_placeholder_txt: 'Minimum Case To Reset Period',
    camera_detail_min_violation_count_error_txt: 'Invalid',
    camera_detail_resolution_label_txt: 'RESOLUTION ( WIDTH x HEIGHT )',
    camera_detail_resolution_error_txt: 'ไม่ถูกต้อง',
    camera_detail_connect_btn_txt: 'เชื่อมต่อ',
    speaker_detail_name_txt: 'ชื่อกล้อง',
    speaker_detail_method_txt: 'Method',
    speaker_detail_url_txt: 'URL',
    speaker_detail_payload_txt: 'Payload',
    speaker_detail_connect_btn_txt: 'เชื่อมต่อ',
    speaker_detail_disconnect_btn_txt: 'ลบ',
    delete_speaker_dialog_title: 'ลบลำโพง',
    delete_speaker_dialog_message: 'คุณต้องการที่จะลบลำโพงใช่หรือไม่ ?',
    delete_speaker_dialog_delete_btn_txt: 'ลบ',
    delete_speaker_dialog_cancel_btn_txt: 'ยกเลิก'
  }
}

export default MESSAGE
